import React, { useEffect, useState } from "react";
import "./assessment.scss";
import Stepper from "../stepper/Stepper";
import AssessMentPercentage from "../../widgets/assessmentPersentage/AssessMentPercentage";
import TextArea from "../common/TextArea";
import TextField from "../common/TextField";
import CheckOption from "../common/CheckOption";
import MultiTextField from "../common/MultiTextField";

import { ApiConfig } from "../../services/ApiConfig";
import PasswordResetModal from "../PasswordResetModal";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import RadioButton from "../common/RadioButton";
import DropDown from "../common/DropDown";
import customAxios from "../../utils/customAxios";
import { useSelector } from "react-redux";

export default function Assessments({
  pageTitle,
  renderData,
  activeStep,
  handlePreviousStep,
  handleNextStep,
  initialAnswers,
  handleInitialAnswers,
}) {
  console.log("renderData: ", renderData);
  const [stepper, setStepper] = useState([]);
  const [answerDetails, setAnswersDetails] = useState([]);
  console.log("answerDetails: ", answerDetails);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const location = useLocation();
  const path = location?.pathname?.split("/");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const formDetails =
    renderData?.assesmentCategoryMasters[activeStep - 1]?.assesmentQuestions;

  const handleFormData = (data) => {
    const arrData = data?.assesmentCategoryMasters;
    const stepperData = arrData
      .filter((item) => {
        if (item.is_active) {
          return item;
        }
      })
      .map((step, i) => {
        return { step: i + 1, title: step?.assesment_category_name };
      });
    setStepper(stepperData);
  };

  useEffect(() => {
    configureAnswerDetail();
  }, [initialAnswers]);

  const configureAnswerDetail = () => {
    const version = parseInt(sessionStorage.getItem("version"));
    if (initialAnswers !== "Assesment not found.") {
      const ans = initialAnswers?.map((data) => {
        return {
          [data?.question_id]: {
            answer: data?.answer,
            answer_id: data?.id,
            assesment_id: data?.assesment_id,
            question_id: data?.question_id,
            assesment_category_id: data?.assesment_category_id,
            version_control: version,
          },
        };
      });
      const mergedAnswers = Object.assign({}, ...ans);
      setAnswersDetails(mergedAnswers);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleUpdateForm = async (data) => {
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.updateUserAssesmentAnswers,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          business_owner_id: sessionStorage.getItem("userId"),
          assesment_id: sessionStorage.getItem("assessmentId"),
          version_control: parseInt(sessionStorage.getItem("version")),
          answer: [data],
        },
      });
      if (res.data.statusCode === 200) {
        handleInitialAnswers();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitForm = async (type) => {
    const version = parseInt(sessionStorage.getItem("version"));
    // const answerList = Object.values(answerDetails);
    console.log(answerDetails, "asbkfafjafsc");
    const updatedAnsers = formDetails.map((item, i) => {
      if (item?.answer_type === "checkBox") {
        if (typeof answerDetails[item.id]?.answer === "string") {
          return {
            answer: answerDetails[item.id].answer,
            question_id: item?.id,
            assesment_category_id: item.assesment_category_,
            assesment_id: item?.assesment_master_id,
            version_control: version,
          };
        } else if (typeof answerDetails[item.id]?.answer === "undefined") {
        } else {
          answerDetails[item.id].answer =
            answerDetails[item.id]?.answer.join(",");
        }
      }
      if (answerDetails[item.id]) {
        return answerDetails[item.id];
      } else {
        return {
          answer: "",
          question_id: item?.id,
          assesment_category_id: item.assesment_category_,
          assesment_id: item?.assesment_master_id,
          version_control: version,
        };
      }
    });
    setIsDataLoading(true);
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.userAssesmentAnswers,

        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: { answer: updatedAnsers },
      });
      if (res.data.statusCode === 200) {
        setIsDataLoading(false);
        handleInitialAnswers();

        if (type === "submit") {
          setIsModalOpen(true);
        } else if (type === "next") {
        } else {
        }
      }
    } catch (error) {
      setIsDataLoading(false);
      console.log(error);
      toast.error("Somthing went wrong!");
    }
  };

  const handleRenderForm = (form, i) => {
    switch (form?.answer_type) {
      case "textArea":
        return (
          <TextArea
          serialNumber={i}
            placeHolder="Type here..."
            formField={form}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answerDetails}
            key={i}
            handleUpdateForm={handleUpdateForm}
            handleSubmitForm={handleSubmitForm}

          />
        );
      case "checkBox":
        return (
          <CheckOption
          serialNumber={i}
            formField={form}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answerDetails}
            handleUpdateForm={handleUpdateForm}
            handleSubmitForm={handleSubmitForm}
            key={i}
          />
        );
      case "radio":
        return (
          <RadioButton
          serialNumber={i}
            formField={form}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answerDetails}
            handleUpdateForm={handleUpdateForm}
            handleSubmitForm={handleSubmitForm}
            key={i}

          />
        );
      case "dropDown":
        return (
          <DropDown
          serialNumber={i}
            formField={form}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answerDetails}
            handleUpdateForm={handleUpdateForm}
            handleSubmitForm={handleSubmitForm}
            key={i}
          />
        );
      case "textBar":
        if (form?.answer.length > 0) {
          return (
            <MultiTextField
            serialNumber={i}
              formField={form}
              setAnswersDetails={setAnswersDetails}
              answersDetails={answerDetails}
              key={i}
              style={{ color: currentTheme === "DARK" ? "#fff" : "#000" }}

            />
          );
        } else {
          return (
            <TextField
            serialNumber={i}
              placeHolder="Type here..."
              formField={form}
              setAnswersDetails={setAnswersDetails}
              answersDetails={answerDetails}
              handleUpdateForm={handleUpdateForm}
              handleSubmitForm={handleSubmitForm}
              key={i}

            />
          );
        }
      default:
        break;
    }
  };

  useEffect(() => {
    handleFormData(renderData);
  }, []);

  const handleNewVersion = async () => {
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.checkActiveVersionControl,

        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          assesment_id: sessionStorage.getItem("assessmentId"),
        },
      });
      if (res.data?.result) {
        const currentVersion = sessionStorage.getItem("version");
        sessionStorage.setItem("version", currentVersion - -1);
        createNewVersion();
      } else {
        toast.error(res.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createNewVersion = async () => {
    const answerList = Object.values(answerDetails);
    const updatedAnsers = answerList.map((answer) => {
      return {
        ...answer,
        version_control: parseInt(sessionStorage.getItem("version")),
      };
    });
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.userAssesmentAnswers,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: { answer: updatedAnsers },
      });
      if (res.data.statusCode === 200) {
        handleInitialAnswers();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff",
      }}
    >
      <div className="discoveryContainer" >
        <h2 style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}>
          {pageTitle}
        </h2>

        <div style={{ margin: "2.5rem 0 3.5rem 0", }}>
          <AssessMentPercentage />
        </div>
        {isDataLoading ? (
          <Box className="spinner">
            <CircularProgress />
          </Box>
        ) : (
          <div className="stepperWrapper" >
            <Stepper data={stepper} activeStep={activeStep} />
            <Box
              sx={{
                width: "60%",
                "@media screen and (max-width: 768px)": {
                  width: "100%",
                },
              }}
            >
              <div className="header">
                <h3 style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}>
                  {stepper[activeStep - 1]?.title}
                </h3>
                {/* <button onClick={() => handleNewVersion()}>
       Create new version
     </button> */}
              </div>

              {formDetails?.length > 0 &&
                formDetails
                  .filter((item) => {
                    if (item.is_active) {
                      return item;
                    }
                  })
                  .map((form, i) => handleRenderForm(form, i))}
              <div className="btnContainer" >

                <div className="btnWrapper" >
                  {/* <button
         className="saveBtn"
         onClick={handleSubmitForm}
         disabled={!Object.values(answerDetails).length > 0}
       >
         Save
       </button> */}
                  {activeStep !== 1 && (
                    <button
                      className="previousBtn"
                      onClick={() => {
                        handleSubmitForm();
                        handlePreviousStep();
                      }}
                    >
                      Previous
                    </button>
                  )}
                  {activeStep !== stepper.length ? (
                    <button
                      className="nextBtn"
                      onClick={() => {
                        handleSubmitForm();
                        handleNextStep();
                      }}
                      disabled={!Object.values(answerDetails).length > 0}
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      className="nextBtn"
                      onClick={() => {
                        setIsModalOpen(true);
                        handleSubmitForm("submit");
                      }}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </Box>
          </div>
        )}
      </div>
      <PasswordResetModal
        type="assessment"
        open={isModalOpen}
        onClose={handleCloseModal}
      />
    </div>
  );
}
